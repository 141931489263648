import { groupBy, isNil } from 'lodash-es';
import { TrpcBase } from '../../../utils/trpc';
import RoomOperation from './roomOperation';
import RuleAdd from './ruleAdd';
import RuleDispatchPanel from './ruleDispatch';
import { useParams } from 'react-router';

export default function RoomConfigurePage() {
	const { id } = useParams();
	const ruleList = TrpcBase.apartment.ruleList.useQuery();

	const roomList = TrpcBase.apartment.roomList.useQuery(id ?? '', {
		enabled: id !== undefined,
	});

	const {
		addButton: RuleOperationButton,
		openRuleEdit,
		render: RoomOperationRender,
	} = RuleAdd({
		onSuccess: () => {
			ruleList.refetch();
		},
	});

	const ruleDispatchDetail = TrpcBase.apartment.ruleDispatchDetail.useQuery();

	return (
		<div className="m-2">
			<div>
				{RoomOperationRender}
				<RoomOperation
					roomList={roomList.data ?? []}
					onSuccess={() => {
						roomList.refetch();
					}}
				/>
			</div>

			{RuleOperationButton}

			<div className="grid sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 gap-1 ">
				{ruleList.data?.map((i) => {
					let targetKeys: any = [];
					if (i?.uuid && ruleDispatchDetail?.data) {
						const d = groupBy(ruleDispatchDetail.data, (i) => i.ruleUuid);
						targetKeys = d?.[i?.uuid];
					}

					const couldBeSelectedRoomList =
						roomList.data?.map((roomItem) => {
							return {
								...roomItem,
								disabled: isNil(
									ruleDispatchDetail?.data?.find(
										(dispatchItem) =>
											dispatchItem.ruleUuid !== i.uuid &&
											dispatchItem.roomUuid === roomItem.uuid,
									),
								)
									? false
									: true,
							};
						}) ?? [];

					return (
						<RuleDispatchPanel
							key={i.uuid}
							currentRule={i}
							roomList={couldBeSelectedRoomList}
							apiTargetKeys={targetKeys}
							changeCallback={async () => {
								await ruleDispatchDetail.refetch();
							}}
							editCallback={openRuleEdit}
						/>
					);
				})}
			</div>
		</div>
	);
}
