import {
	Button,
	Card,
	Col,
	Drawer,
	Form,
	Input,
	InputNumber,
	Popconfirm,
	Row,
	Switch,
	message,
} from 'antd';
import { TrpcBase } from '../../../utils/trpc';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { SuccessCallbackType } from '../buildingConfigure/SuccessCallbackType';
import { isNil, omit } from 'lodash-es';
import JsonView from '@uiw/react-json-view';

const configureItem = [
	{
		name: 'coldWaterFee',
		label: '冷水',
		unit: '元/m³',
	},
	{
		name: 'hotWaterFee',
		label: '热水',
		unit: '元/m³',
	},
	{
		name: 'electricityFee',
		label: '电费',
		unit: '元/kW·h',
	},
	{
		name: 'tvFee',
		label: '电视',
		unit: '元/月',
	},
	{
		name: 'networkFee',
		label: '网络',
		unit: '元/月',
	},
	{
		name: 'cleanFee',
		label: '卫生',
		unit: '元/月',
	},
];

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 },
	},
};

export default function RuleAdd({ onSuccess }: SuccessCallbackType) {
	const [form] = Form.useForm();
	const action = TrpcBase.apartment.createRule.useMutation();
	const [uuid, setUuid] = useState<string>('');
	const [open, setOpen] = useState(false);
	const detail = TrpcBase.apartment.ruleDetail.useQuery(uuid, {
		enabled: '' !== uuid,
	});

	useEffect(() => {
		const formData = detail.data;

		form.setFieldsValue({
			id: uuid,
			name: formData?.name,
			...formData?.valuation,
		});
	}, [detail.data]);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	return {
		addButton: (
			<Button type="primary" onClick={showDrawer}>
				配置添加
			</Button>
		),

		deleteRule: async (uuid: string) => {},
		openRuleEdit: async (uuid: string) => {
			showDrawer();
			setUuid(uuid);
		},
		render: (
			<>
				<Drawer
					title="配置更新"
					placement="right"
					onClose={onClose}
					open={open}
					forceRender={true}
					width={800}
				>
					<Form
						{...formItemLayout}
						form={form}
						onFinish={async (values) => {
							console.log(values);

							const json = form.getFieldsValue();
							const conf = JSON.stringify(omit(omit(json, 'id'), 'name'));

							const res = await action.mutateAsync({
								id: values.id,
								name: values.name,
								valuation: conf,
							});

							if (res.rowCount <= 0) {
								message.error('添加失败');
								return;
							}

							message.success('添加成功');
							setOpen(false);
							onSuccess?.();
						}}
					>
						<Form.Item name="id" hidden={true}>
							<Input />
						</Form.Item>
						<Form.Item name="name" label="配置名">
							<Input />
						</Form.Item>

						{configureItem.map((i) => {
							return (
								<div key={i.name}>
									<Form.Item
										name={[i.name, 'value']}
										label={`${i.label}费用单价`}
										initialValue={0}
									>
										<InputNumber />
									</Form.Item>
									<Form.Item
										name={[i.name, 'unit']}
										label={`${i.label}费用单元`}
										initialValue={i.unit}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name={[i.name, 'disabled']}
										label={`${i.label}隐藏`}
										initialValue={false}
										valuePropName="checked"
									>
										<Switch />
									</Form.Item>
								</div>
							);
						})}

						<Form.Item label="预览配置值" shouldUpdate={true}>
							{(f) => {
								const json = f.getFieldsValue();
								const conf = omit(omit(json, 'id'), 'name');
								return <JsonView value={conf} />;
							}}
						</Form.Item>

						<Form.Item>
							<Button
								onClick={() => {
									form.submit();
								}}
							>
								{uuid ? '更新' : '创建'}
							</Button>
						</Form.Item>
					</Form>
				</Drawer>
			</>
		),
	};
}
