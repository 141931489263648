import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Spin, Transfer, message } from 'antd';
import type { TransferDirection } from 'antd/lib/transfer';

import ButtonGroup from 'antd/lib/button/button-group';
import { useState } from 'react';
import { type RouterOutputs, TrpcBase } from '../../../utils/trpc';

export default function RuleDispatchPanel({
	currentRule,
	roomList,
	apiTargetKeys,
	changeCallback,
	editCallback,
}: {
	currentRule: RouterOutputs['apartment']['ruleList'][number];
	roomList: RouterOutputs['apartment']['roomList'];
	apiTargetKeys: RouterOutputs['apartment']['ruleDispatchDetail'];
	changeCallback: () => void;
	editCallback: (uuid: string) => Promise<void>;
}) {
	const dispatchSet = TrpcBase.apartment.ruleDispatchSet.useMutation();
	const targetKeys = apiTargetKeys?.map((i) => i?.roomUuid) ?? [];
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

	const onChange = async (
		nextTargetKeys: string[],
		direction: TransferDirection,
		moveKeys: string[],
	) => {
		await dispatchSet.mutateAsync({
			ruleUuid: currentRule.uuid,
			roomUuid: nextTargetKeys,
		});

		changeCallback?.();
	};

	const onSelectChange = (
		sourceSelectedKeys: string[],
		targetSelectedKeys: string[],
	) => {
		console.log('sourceSelectedKeys:', sourceSelectedKeys);
		console.log('targetSelectedKeys:', targetSelectedKeys);
		setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
	};

	const onScroll = (
		direction: TransferDirection,
		e: React.SyntheticEvent<HTMLUListElement>,
	) => {
		console.log('direction:', direction);
		console.log('target:', e.target);
	};

	const handleSearch = (dir: TransferDirection, value: string) => {
		console.log('search:', dir, value);
	};

	return (
		<Card
			// className='my-2'
			className="overflow-y-auto m-2"
			key={currentRule.uuid}
			title={`房间配置 - ${currentRule.name}`}
			extra={
				<ButtonGroup>
					<Button
						onClick={() => {
							editCallback?.(currentRule.uuid);
						}}
					>
						<EditOutlined
							onPointerEnterCapture={undefined}
							onPointerLeaveCapture={undefined}
						/>
						修改
					</Button>
					<Popconfirm
						title="确认进行删除吗?"
						onConfirm={async () => {
							// const res = await delAction.mutateAsync({
							//   uuid: record.uuid,
							// });
							message.success('暂不支持');
						}}
						disabled={true}
						onCancel={() => {}}
						okText="确定"
						cancelText="取消"
					>
						<Button disabled={true}>删除</Button>
					</Popconfirm>
				</ButtonGroup>
			}
		>
			<Spin spinning={dispatchSet.isLoading}>
				<Transfer
					rowKey={(record) => record.uuid}
					oneWay
					showSearch
					dataSource={roomList}
					titles={['未配置规则房间', '已配置规则房间']}
					targetKeys={targetKeys}
					selectedKeys={selectedKeys}
					onChange={onChange}
					onSelectChange={onSelectChange}
					onScroll={onScroll}
					render={(item) => item.roomNumber}
				/>
			</Spin>
		</Card>
	);
}
