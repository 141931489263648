import { useTitle } from 'ahooks';
import {
	Button,
	Card,
	Drawer,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Space,
	Table,
	message,
} from 'antd';
import { roomTypeEnumeration } from 'blog/type-file';
import { useState } from 'react';
import { useParams } from 'react-router';
import { type RouterOutputs, TrpcBase } from '../../../utils/trpc';
import type { SuccessCallbackType } from '../buildingConfigure/SuccessCallbackType';
import { SuperDescription } from '../components/SuperDescription';
import { globalAntdPagination } from '../util/globalAntdPagination';
import { RoomStatusConvert } from './consts';

interface RoomOperationType extends SuccessCallbackType {
	roomList: RouterOutputs['apartment']['roomList'];
}

export default function RoomOperation({
	roomList,
	onSuccess,
}: RoomOperationType) {
	useTitle('房源配置');
	const { id } = useParams();

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const [form] = Form.useForm();
	const action = TrpcBase.apartment.createRoom.useMutation();
	const delAction = TrpcBase.apartment.delRoom.useMutation();
	const CreateOneRoom = TrpcBase.apartment.CreateOneRoom.useMutation();
	const [open, setOpen] = useState(false);
	const [modal, setModal] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	return (
		<>
			{/* onOk={handleOk} onCancel={handleCancel} */}
			<Modal
				open={modal}
				onCancel={() => {
					setModal(false);
				}}
			>
				<SuperDescription
					obj={{
						roomNumber: '',
						roomType: roomTypeEnumeration.room,
						description: '',
					}}
					title="添加房间"
					updateAction={CreateOneRoom}
					newLocal={[
						{
							label: '房间号',
							field: 'roomNumber',
							isKey: true,
							isEdit: true,
						},
						{
							label: '房间类型',
							field: 'roomType',
						},
						{
							label: '备注',
							field: 'description',
							isEdit: true,
						},
					]}
					initEdit={true}
				/>
			</Modal>
			<Space>
				<Button type="primary" onClick={showDrawer}>
					批量添加
				</Button>

				<Button
					type="default"
					onClick={async () => {
						// await CreateOneRoom.mutateAsync({ uuid: selectedRowKeys as string[] });
						onSuccess?.();
						setModal(true);
					}}
				>
					添加
				</Button>

				<Popconfirm
					title={'确定批量产生吗'}
					disabled={true}
					onConfirm={async () => {
						if (selectedRowKeys.length === 0) {
							message.error('请先选中房间');
							return;
						}

						await delAction.mutateAsync({ uuid: selectedRowKeys as string[] });
						onSuccess?.();
					}}
				>
					<Button type="default" disabled={true}>
						删除
					</Button>
				</Popconfirm>
				<div>生效房数量:{roomList?.filter((i) => i.enabled === 1).length}</div>
			</Space>
			<Drawer
				title="批量产生房间号"
				placement="right"
				onClose={onClose}
				open={open}
			>
				<Form
					form={form}
					initialValues={{
						id: id,
						level: 0,
						count: 0,
					}}
					onFinish={async (values) => {
						console.log(values);

						const res = await action.mutateAsync(values);

						if (res.rowCount <= 0) {
							message.error('添加失败');
							return;
						}

						message.success('添加成功');
						onSuccess?.();
					}}
				>
					<Form.Item name="id" hidden={true}>
						<Input />
					</Form.Item>
					<Form.Item name="level" label="楼层数">
						<InputNumber />
					</Form.Item>
					<Form.Item name="count" label="每层楼房间数量">
						<InputNumber />
					</Form.Item>

					<Form.Item label="预览将会产生的房间" shouldUpdate={true}>
						{(f) => {
							const level = Number.parseInt(f.getFieldValue('level'));
							const count = Number.parseInt(f.getFieldValue('count'));
							return new Array(level).fill(0).map((i, idx) => {
								const roomLevel = idx + 1;

								return (
									<div className="flex flex-row flex-wrap">
										{new Array(count).fill(0).map((_, rmIdx) => {
											const roomNumber = rmIdx + 1;
											const roomLength =
												count.toString().length < 2
													? 2
													: count.toString().length;

											return (
												<div className="m-2">
													{(
														roomLevel * 10 ** roomLength +
														roomNumber
													).toString()}
												</div>
											);
										})}
									</div>
								);
							});
						}}
					</Form.Item>

					<Form.Item>
						<Popconfirm
							title={'确定批量产生吗'}
							onConfirm={() => {
								form.submit();
							}}
						>
							<Button>批量产生</Button>
						</Popconfirm>
					</Form.Item>
					{action.error && <p>Something went wrong! {action.error.message}</p>}
				</Form>
			</Drawer>

			<Card className="my-2" title={'现有房间'}>
				<Table
					rowSelection={{
						selectedRowKeys: selectedRowKeys,
						onChange: (i) => {
							setSelectedRowKeys(i);
						},
					}}
					columns={[
						{
							title: '房间名',
							dataIndex: 'roomNumber',
						},
						{
							title: '房间状态',
							dataIndex: 'roomStatus',
							render: (v) => {
								// @ts-expect-error not time
								return v in RoomStatusConvert ? RoomStatusConvert[v] : v;
							},
						},
						{
							title: '房间类型',
							dataIndex: 'roomType',
							render: (v) => (v === roomTypeEnumeration.room ? '房间' : v),
						},
						{
							title: '备注',
							dataIndex: 'descriptionNote',
						},
						{
							title: '启用',
							dataIndex: 'enabled',
							render: (v) => {
								return v === 1 ? '正常' : '禁用';
							},
						},
					]}
					rowKey={(i) => i.uuid}
					dataSource={roomList}
					pagination={globalAntdPagination}
				/>
			</Card>
		</>
	);
}
